import { Injectable } from "@angular/core";
import { GlobalProviderService } from "../providers/global-provider.service";
import { environment } from "../../environments/environment";
// import Bugsnag from '@bugsnag/js';

@Injectable({
  providedIn: "root",
})
export class F2fapiService {
  public baseUrl = environment.BASE_URL;
  constructor(private globalProvider: GlobalProviderService) { }

  /*
   ** Get profile
   */
  public getUserProfile() {
    const url = this.baseUrl + "user/profile";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Get User Profile  error", err);
          reject(err);
        }
      );
    });
  }

  public updateUserProfile(profileObj) {
    const url = this.baseUrl + "user/update_profile";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, profileObj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Update User Profile  error", err);
          reject(err);
        }
      );
    });
  }

  /*
   ** Register User
   */
  public registerUser(userObj) {
    const url = this.baseUrl + "register";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, userObj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Register error", err);
          reject(err);
        }
      );
    });
  }

  /*
   ** Login user
   */
  public loginUser(userObj) {
    const url = this.baseUrl + "login";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, userObj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Register error", err);
          reject(err);
        }
      );
    });
  }

  /*
   ** Restaurants APIs
   */
  public getRestaurantsList() {
    const url = this.baseUrl + "restaurants/?per_page=0";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Restaurant list error", err);
          reject(err);
        }
      );
    });
  }

  public getRestaurantDetails(restaurantId) {
    const url = this.baseUrl + "restaurants/details/" + restaurantId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Restaurant Details error", err);
          reject(err);
        }
      );
    });
  }

  public createRestaurant(obj) {
    const url = this.baseUrl + "restaurants/";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Restaurant Details error", err);
          reject(err);
        }
      );
    });
  }

  public updateRestaurant(obj, restuarantId) {
    const url = this.baseUrl + "restaurants/update/" + restuarantId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Restaurant Details error", err);
          reject(err);
        }
      );
    });
  }

  public deleteRestaurantDetail(restuarantId) {
    const url = this.baseUrl + "restaurants/delete/" + restuarantId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpDelete(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Delete Restaurant Details error", err);
          reject(err);
        }
      );
    });
  }

  public verifyRestaurant(restuarantId, verified: boolean) {
    const url = this.baseUrl + "restaurants/verify/" + restuarantId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, { is_verified: verified }).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Verify Restaurant Details error", err);
          reject(err);
        }
      );
    });
  }

  public getSubscriptionProducts() {
    const url = this.baseUrl + "products/";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Subscription Product List Error", err);
          reject(err);
        }
      );
    });
  }

  public getSetupFeesDetails() {
    const url = this.baseUrl + "setup_fees/";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Setup Fees Error", err);
          reject(err);
        }
      );
    });
  }

  public createSetupFeesPaymentIntent(restaurantId) {
    const url =
      this.baseUrl + "restaurants/" + restaurantId + "/payment_intent";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Create Setup Fees Payment Intent Error ", err);
          reject(err);
        }
      );
    });
  }

  public getRestaurantSetupDetails(restaurantId, obj) {
    const url = this.baseUrl + "restaurants/" + restaurantId + "/setup";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Restaurant Setup Details Error", err);
          reject(err);
        }
      );
    });
  }

  /**
   * User Change Password
   */
  public changePassword(userObj) {
    const url = this.baseUrl + "user/change_password";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, userObj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("User Password Change Error", err);
          reject(err);
        }
      );
    });
  }

  /**
   * Forgot Password
   */
  public forgotPassword(userObj) {
    const url = this.baseUrl + "forgot_password";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, userObj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Forgot Password Error", err);
          reject(err);
        }
      );
    });
  }

  /**
   * Reset Password Using Reset Link From Mail
   */
  public resetPassword(userObj, token) {
    const url = this.baseUrl + "reset_password/" + token;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, userObj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Forgot Password Error", err);
          reject(err);
        }
      );
    });
  }

  /**
   * Get Plans
   */
  public getAllPlans() {
    const url = this.baseUrl + "plans";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("All Plans Error ", err);
          reject(err);
        }
      );
    });
  }

  public selectPlan(restaurantId, planObj) {
    const url = this.baseUrl + "restaurants/" + restaurantId + "/select_plan";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, planObj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Select Plan Error ", err);
          reject(err);
        }
      );
    });
  }

  /**
   * Cuisine APIs
   */
  public getCuisinesList() {
    const url = this.baseUrl + "cuisines/?per_page=0";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Cuisine list error", err);
          reject(err);
        }
      );
    });
  }

  public createCuisine(obj) {
    const url = this.baseUrl + "cuisines/";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Cuisine Add error", err);
          reject(err);
        }
      );
    });
  }

  public deleteCuisineDetail(cuisineId) {
    const url = this.baseUrl + "cuisines/delete/" + cuisineId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpDelete(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Delete Cuisine Details error", err);
          reject(err);
        }
      );
    });
  }

  public getCuisineDetails(cuisineId) {
    const url = this.baseUrl + "cuisines/details/" + cuisineId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Cuisine Details error", err);
          reject(err);
        }
      );
    });
  }

  public updateCuisine(obj, cuisineId) {
    const url = this.baseUrl + "cuisines/update/" + cuisineId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Cuisine Update error", err);
          reject(err);
        }
      );
    });
  }

  public uploadImageCuisine(obj, cuisineId) {
    const url = this.baseUrl + "cuisines/upload_image/" + cuisineId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Cuisine Upload Image error", err);
          reject(err);
        }
      );
    });
  }

  /**
   * Drink APIs
   */
  public getDrinksList() {
    const url = this.baseUrl + "drinks/?per_page=0";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Drink list error", err);
          reject(err);
        }
      );
    });
  }

  public createDrink(obj) {
    const url = this.baseUrl + "drinks/";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Drink Add error", err);
          reject(err);
        }
      );
    });
  }

  public deleteDrinkDetail(drinkId: any) {
    const url = this.baseUrl + "drinks/delete/" + drinkId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpDelete(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Delete Drink Details error", err);
          reject(err);
        }
      );
    });
  }

  public getDrinkDetails(drinkId: any) {
    const url = this.baseUrl + "drinks/details/" + drinkId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Drink Details error", err);
          reject(err);
        }
      );
    });
  }

  public updateDrink(obj: any, drinkId: any) {
    const url = this.baseUrl + "drinks/update/" + drinkId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Drink Update error", err);
          reject(err);
        }
      );
    });
  }

  public uploadDrinkImage(obj: any, drinkId: any) {
    const url = this.baseUrl + "drinks/upload_image/" + drinkId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Drink Upload Image error", err);
          reject(err);
        }
      );
    });
  }

  /**
   * Fulfilment Type APIs
   */
  public getFulfilmentTypesList() {
    const url = this.baseUrl + "fulfilment_types/";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("FulfilmentType list error", err);
          reject(err);
        }
      );
    });
  }

  public createFulfilmentType(obj) {
    const url = this.baseUrl + "fulfilment_types/";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("FulfilmentType Add error", err);
          reject(err);
        }
      );
    });
  }

  public deleteFulfilmentTypeDetail(fulfilmentTypeId) {
    const url = this.baseUrl + "fulfilment_types/delete/" + fulfilmentTypeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpDelete(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Delete FulfilmentType Details error", err);
          reject(err);
        }
      );
    });
  }

  public getFulfilmentTypeDetails(fulfilmentTypeId) {
    const url = this.baseUrl + "fulfilment_types/details/" + fulfilmentTypeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("FulfilmentType Details error", err);
          reject(err);
        }
      );
    });
  }

  public updateFulfilmentType(obj, fulfilmentTypeId) {
    const url = this.baseUrl + "fulfilment_types/update/" + fulfilmentTypeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("FulfilmentType Update error", err);
          reject(err);
        }
      );
    });
  }

  /**
   * Coupon APIs
   */
  public getCouponsList() {
    const url = this.baseUrl + "coupons/all";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Coupons list error", err);
          reject(err);
        }
      );
    });
  }

  public searchCoupons(obj) {
    // Search by code/title/description/type
    const url = this.baseUrl + "coupons/filter";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Coupon Filter error", err);
          reject(err);
        }
      );
    });
  }

  /**
   * Ethnicities APIs
   */
  public getEthnicitiesList() {
    const url = this.baseUrl + "ethnicities/";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Coupons list error", err);
          reject(err);
        }
      );
    });
  }

  public getEthnicitiesDetails(fulfilmentTypeId) {
    const url = this.baseUrl + "ethnicities/details/" + fulfilmentTypeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("FulfilmentType Details error", err);
          reject(err);
        }
      );
    });
  }

  public deleteEthnicitiesDetail(fulfilmentTypeId) {
    const url = this.baseUrl + "ethnicities/delete/" + fulfilmentTypeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpDelete(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Delete FulfilmentType Details error", err);
          reject(err);
        }
      );
    });
  }

  public updateEthnicitie(obj, fulfilmentTypeId) {
    const url = this.baseUrl + "ethnicities/update/" + fulfilmentTypeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("FulfilmentType Update error", err);
          reject(err);
        }
      );
    });
  }

  public createEthnicities(obj) {
    const url = this.baseUrl + "ethnicities/";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Cuisine Add error", err);
          reject(err);
        }
      );
    });
  }

  /**
   * Customers API
   */

  public getCustomersList() {
    const url = this.baseUrl + "customers/all/?per_page=0";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Customer list error", err);
          reject(err);
        }
      );
    });
  }

  public getCustomerDetails(id: any) {
    const url = this.baseUrl + "customers/details/" + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Customer Details error", err);
          reject(err);
        }
      );
    });
  }

  public getDatingUserList(perPageData, pageNumber, filterObj) {
    const url = this.baseUrl + "dating_user/filter?per_page=" + perPageData + "&page=" + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, filterObj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Dating User list error", err);
          reject(err);
        }
      );
    });
  }

  public getDatingUserApproveStatusChange(id, obj) {
    const url = this.baseUrl + "dating_user/approve/" + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Dating User Approve Status Change error", err);
          reject(err);
        }
      );
    });
  }

  public getDatingProfile(id: any) {
    const url = this.baseUrl + "dating_user/details/" + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Dating Profile Details error", err);
          reject(err);
        }
      );
    });
  }

  public getDatingQuestions(perPageData, pageNumber) {
    const url = this.baseUrl + "dating_questions?per_page=" + perPageData + "&page=" + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Get All dating questions error", err);
          reject(err);
        }
      );
    });
  }

  public getDatingFilterMasterData() {
    const url = this.baseUrl + "dating_user/filter/master";
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("Get All dating filter master data error", err);
          reject(err);
        }
      );
    });
  }

}
